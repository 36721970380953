
import GwCard from '~/patterns/atoms/card/card';
import GwIcon from '~/patterns/atoms/icon/icon';
import GwModal from '~/patterns/organisms/modal/modal';

export default {
    components: {
        GwCard,
        GwIcon,
        GwModal
    },
    data() {
        return {
            showModal: false
        };
    }
};
