
import GwSection from '~/patterns/organisms/section/section.vue';
import GwCardGrid from '~/patterns/molecules/card-grid/card-grid';
import GwModalCard from '~/patterns/organisms/modal-card/modal-card';

import ImageSingle from '~/patterns/atoms/image-single/image-single.vue';

export default {
    components: {
        GwSection,
        GwCardGrid,
        GwModalCard,
        ImageSingle
    },
    props: {
        heading: {
            type: String,
            required: false,
            default: null
        },
        cards: {
            type: Array,
            required: false,
            default: () => { return []; },
        }
    },
    data() {
        return {
            showModal: false
        };
    }
};
